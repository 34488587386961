// /components/NavBar.tsx
import { Link } from 'react-router-dom';  // Correct import
import styles from '../styles/NavBar.module.css';
import LoginButton from './LoginButton';

const NavBar = () => {
  return (
      <nav className={`${styles.navbar} text-white p-4`}>
          <div className="container mx-auto flex justify-center items-center">
              <div className="flex-grow flex justify-center items-center space-x-4">
                  <Link to="/" className={styles.navLink}>Home</Link>
                  <Link to="/flights" className={styles.navLink}>Flighs</Link>
                  <Link to="/dashboard" className={styles.navLink}>Dashboard</Link>
                  <Link to="/upload" className={styles.navLink}>Upload</Link>
                  <Link to="/chat" className={styles.navLink}>Chat</Link>
                  <Link to="/powerbi" className={styles.navLink}>Intelligence</Link>
                  <Link to="/settings" className={styles.navLink}>Settings</Link>
        
              </div>
              <div className="flex items-center space-x-4">
                  <LoginButton />
              </div>
          </div>
      </nav>
  );
};

export default NavBar;


