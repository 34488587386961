// /components/home.tsx
import React from 'react';
import styles from '../styles/HomeComponent.module.css'; // Ensure this path is correct

const HomeComponent: React.FC = () => (
  <div className={styles.homeBackground}>
    {/* Any additional content for the home page goes here */}
  </div>
);

export default HomeComponent;
