import { AIChatCompletion, AIChatCompletionDelta, AIChatCompletionOperationOptions } from "@microsoft/ai-chat-protocol";

export type Config = {
    showGPT4VOptions: boolean;
    showSemanticRankerOption: boolean;
    showVectorOption: boolean;
    showUserUpload: boolean;
    showSpeechInput: boolean;
    showSpeechOutputBrowser: boolean;
    showSpeechOutputAzure: boolean;
};

export type SimpleAPIResponse = {
    message?: string;
};

export interface Flight {
    id: number;
    start_date: string;
    start_time: string;
    start_location: string;
    end_date: string;
    end_time: string;
    end_location: string;
    fleet: string;
    aircraft: string;
    foqa_status: string;
    departure: string;
    departure_rwy: string;
    dep_datetime: string;
    arrival: string;
    arrival_rwy: string;
    arr_datetime: string;
    foqa_comment: string;
    type: string;
  }

  export interface EventData {
    id: number;             // Event ID
    time: Date;             // Event time as a Date object
    event_id: number;       // Event ID (float in Python, using number in TypeScript)
    filtered: string;       // Filtered status (string)
    phase: string;          // Flight phase
    reason: string; 
    reason_comment: string;            // Reason for the event (Text)
    level: number;          // Event level (integer)
    deviation_category: string;  // Category of deviation
    deviation: string;      // Specific deviation
    deviation_value: number;  // Value of deviation (float)
    name_value_pairs: string;
    invalidated_event: boolean;  // Additional event metadata as a string
    flight_id: number;      // Foreign key reference to FlightInfo (int)
  }
  
  export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export type ChatAppRequestOverrides = {
    use_advanced_flow?: boolean;
    retrieval_mode?: RetrievalMode;
    top?: number;
    temperature?: number;
    prompt_template?: string;
};

export type ChatAppRequestContext = {
    overrides: ChatAppRequestOverrides;
};

export interface ChatAppRequestOptions extends AIChatCompletionOperationOptions {
    context: ChatAppRequestContext
}

export type Thoughts = {
    title: string;
    description: any; // It can be any output from the api
    props?: { [key: string]: string };
};

export type RAGContext = {
    data_points: { [key: string]: any };
    followup_questions: string[] | null;
    thoughts: Thoughts[];
};

export interface RAGChatCompletion extends AIChatCompletion {
    context: RAGContext;
}

export interface RAGChatCompletionDelta extends AIChatCompletionDelta {
    context: RAGContext;
}