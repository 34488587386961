//src/frontend/src/layoutWrapper.tsx
import { AccountInfo, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, useLogin } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { LoginProvider } from "./contexts/loginContext";
import MainLayout from "./layouts/MainLayout";

const LayoutWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (useLogin) {
        const msalInstance = new PublicClientApplication(msalConfig);

        // Default to using the first account if no account is active on page load
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
            msalInstance.setActiveAccount(msalInstance.getActiveAccount());
        }

        // Listen for sign-in event and set active account
        msalInstance.addEventCallback(event => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const account = event.payload as AccountInfo;
                msalInstance.setActiveAccount(account);
            }
        });

        return (
            <MsalProvider instance={msalInstance}>
                <LoginProvider client={msalInstance}>
                    <MainLayout>{children}</MainLayout>
                </LoginProvider>
            </MsalProvider>
        );
    } else {
        return (
            <LoginProvider client={undefined as any}> {/* Pass undefined client if not using MSAL */}
                <MainLayout>{children}</MainLayout>
            </LoginProvider>
        );
    }
};

export default LayoutWrapper;

