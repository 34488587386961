/* import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { fetchEmbedToken } from '../api';

const PowerBIPage: React.FC = () => {
    const [embedToken, setEmbedToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchToken = async () => {
        try {
          const token = await fetchEmbedToken(); // Call the function to fetch embed token
          setEmbedToken(token);
        } catch (err) {
          setError('Failed to load embed token');
          console.error('Error loading embed token:', err);
        }
      };
  
      fetchToken();
    }, []);
  
    if (!embedToken) return <div>{error || 'Loading Power BI Report...'}</div>;

  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        id: 'your-report-id',
        embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=your-report-id',
        accessToken: embedToken,
        tokenType: models.TokenType.Embed,
      }}
      eventHandlers={new Map([
        ['rendered', () => console.log('Report rendered')],
        ['error', (event: any) => console.error('Error occurred', event.detail)],
        ['loaded', () => console.log('Report loaded')],
      ])}
      cssClassName="report-style-class"
    />
  );
};

export default PowerBIPage; */

import React, { useState } from 'react';

const PowerBIPage: React.FC = () => {
    const [error] = useState<string | null>(null);

    if (error) {
        return <div>{error}</div>;
    }

    return (
      <iframe
          title="Sample Report Demo"
          width="1140"
          height="541.25"
          src="https://playground.powerbi.com/sampleReportEmbed"
          style={{ border: 'none' }} // Remove border using CSS
          allowFullScreen={true}
      ></iframe>
    );
};

export default PowerBIPage;


