// src/layouts/RootLayout.tsx
import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';  // Import Chakra's Box and useColorModeValue

const RootLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Use rgb(38, 38, 41) for dark mode
  const bgColor = useColorModeValue('white', 'rgb(38, 38, 41)');  // Light mode: white, Dark mode: rgb(38, 38, 41)

  return (
    <Box className="flex flex-col h-full md:p-8" bg={bgColor} fontFamily="Inter, sans-serif">
      {children}
    </Box>
  );
};

export default RootLayout;

