import React, { useState, useEffect,ChangeEvent } from 'react';
import { Box, Button, Flex, Table, Thead, Tbody, Tr, Th, Td, Text, Input,Spinner } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { listUploadedFilesApi, uploadFileApi, deleteUploadedFileApi,SimpleAPIResponse } from '../api'; // Import functions from api.ts
import styles from '../styles/Upload.module.css';
import { useMsal } from "@azure/msal-react";
import { useLogin, getToken } from "../authConfig";

interface Props {
  className?: string;
  disabled?: boolean;
}

export const UploadFile: React.FC<Props> = ({ className, disabled }: Props) => {
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deletionStatus, setDeletionStatus] = useState<{ [filename: string]: "pending" | "error" | "success" }>({});
  const [uploadedFile, setUploadedFile] = useState<SimpleAPIResponse>();
  const [uploadedFileError, setUploadedFileError] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  

  if (!useLogin) {
    throw new Error("The UploadFile component requires useLogin to be true");
}

const client = useMsal().instance;

// Handler for the "Manage file uploads" button
const handleButtonClick = async () => {
    setIsCalloutVisible(!isCalloutVisible); // Toggle the Callout visibility

    // Update uploaded files by calling the API
    try {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        listUploadedFiles(idToken);
    } catch (error) {
        console.error(error);
        setIsLoading(false);
    }
};

const listUploadedFiles = async (idToken: string) => {
    listUploadedFilesApi(idToken).then(files => {
        setIsLoading(false);
        setDeletionStatus({});
        setUploadedFiles(files);
    });
};

const handleRemoveFile = async (filename: string) => {
    setDeletionStatus({ ...deletionStatus, [filename]: "pending" });

    try {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }

        await deleteUploadedFileApi(filename, idToken);
        setDeletionStatus({ ...deletionStatus, [filename]: "success" });
        listUploadedFiles(idToken);
    } catch (error) {
        setDeletionStatus({ ...deletionStatus, [filename]: "error" });
        console.error(error);
    }
};

// Handler for the form submission (file upload)
const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) {
        return;
    }
    setIsUploading(true); // Start the loading state
    const file: File = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
        const idToken = await getToken(client);
        if (!idToken) {
            throw new Error("No authentication token available");
        }
        const response: SimpleAPIResponse = await uploadFileApi(formData, idToken);
        setUploadedFile(response);
        setIsUploading(false);
        setUploadedFileError(undefined);
        listUploadedFiles(idToken);
    } catch (error) {
        console.error(error);
        setIsUploading(false);
        setUploadedFileError(`Error uploading file - please try again or contact admin.`);
    }
};

  return (
    <Box className={styles.backgroundImage}>  {/* Use the background image */}
      <Box className={`${styles.container} ${className ?? ""}`}>
        <Flex direction="column" justifyContent="center" alignItems="center" py={6}>
          <Button
            id="calloutButton"
            leftIcon={<AddIcon />}
            onClick={handleButtonClick}
            isDisabled={disabled}
            colorScheme="blue"
          >
            Manage file uploads
          </Button>
  
          {isCalloutVisible && (
            <Box mt={4} p={4} borderRadius="md" boxShadow="lg" bg="white">
              <form encType="multipart/form-data">
                <Box mb={4}>
                  <Text mb={2}>Upload file:</Text>
                  <Input
                    type="file"
                    accept=".txt, .md, .json, .png, .jpg, .jpeg, .bmp, .heic, .tiff, .pdf, .docx, .xlsx, .pptx, .html, .tsc"
                    onChange={handleUploadFile}
                    isDisabled={isUploading}
                  />
                </Box>
              </form>
  
              {/* Display upload status */}
              {isUploading && (
                <Flex justifyContent="center" alignItems="center" mt={4}>
                  <Spinner size="md" />
                  <Text ml={2}>Uploading files...</Text>
                </Flex>
              )}
              {!isUploading && uploadedFileError && (
                <Text color="red.500" mt={4}>
                  {uploadedFileError}
                </Text>
              )}
              {!isUploading && uploadedFile && (
                <Text color="green.500" mt={4}>
                  {uploadedFile.message}
                </Text>
              )}
  
              <Text fontSize="lg" fontWeight="bold" mt={6}>
                Previously uploaded files:
              </Text>
  
              {/* Show loading state */}
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" mt={4}>
                  <Spinner size="md" />
                  <Text ml={2}>Loading...</Text>
                </Flex>
              ) : uploadedFiles.length === 0 ? (
                <Text>No files uploaded yet</Text>
              ) : (
                <Table variant="simple" mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Filename</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {uploadedFiles.map((filename, index) => (
                      <Tr key={index}>
                        <Td>{filename}</Td>
                        <Td>
                          <Button
                            leftIcon={<DeleteIcon />}
                            onClick={() => handleRemoveFile(filename)}
                            isDisabled={deletionStatus[filename] === "pending" || deletionStatus[filename] === "success"}
                            colorScheme="red"
                          >
                            {deletionStatus[filename] === "pending"
                              ? "Deleting..."
                              : deletionStatus[filename] === "error"
                              ? "Error deleting"
                              : deletionStatus[filename] === "success"
                              ? "File deleted"
                              : "Delete file"}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
