/**
 * This file defines a context for managing login state in a React application.
 * Context provides a way to pass data through the component tree without having to pass props down manually at every level.
 * For more information, refer to the official React documentation:
 * https://react.dev/learn/passing-data-deeply-with-context
 */



import { createContext, useState, useEffect } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { getFullName, getUsername } from "../authConfig";

interface LoginContextType {
    loggedIn: boolean;
    setLoggedIn: (loggedIn: boolean) => void;
    displayName: string;
    setDisplayName: (name: string) => void;
}


export const LoginContext = createContext<LoginContextType>({
    loggedIn: false,
    setLoggedIn: (_: boolean) => {},
    displayName: "",
    setDisplayName: (_: string) => {}
});

export const LoginProvider: React.FC<{ client: IPublicClientApplication, children: React.ReactNode }> = ({ client, children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [displayName, setDisplayName] = useState<string>("");

    useEffect(() => {
        const fetchDisplayName = async () => {
            const fullName = await getFullName(client);
            if (fullName) {
                setDisplayName(fullName);
            } else {
                setDisplayName((await getUsername(client)) ?? "");
            }
        };

        if (loggedIn) {
            fetchDisplayName();
        }
    }, [client, loggedIn]);

    return (
        <LoginContext.Provider value={{ loggedIn, setLoggedIn, displayName, setDisplayName }}>
            {children}
        </LoginContext.Provider>
    );
};
