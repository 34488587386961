import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import LayoutWrapper from './layoutWrapper'; 
import HomeComponent from './pages/home';
import PostComponent from './pages/dashboard';
import PowerBIPage from './pages/intelligence';
import FlightsTable from './pages/flights';
import { UploadFile } from './pages/upload';
import Settings from './pages/settings';
import 'bootstrap/dist/css/bootstrap.min.css';
import Chat from "./pages/chat/Chat";
import './index.css';


const App: React.FC = () => {
  console.log("App component rendered");
  return (
    
      <Router>
        <LayoutWrapper> {/* Wrap all routes inside LayoutWrapper */}
        <RootLayout>
          <Routes>
           <Route path="/" element={<HomeComponent />} />
              <Route path="/dashboard" element={<PostComponent />} />
              <Route path="/flights" element={<FlightsTable />} />
              <Route path="/powerbi" element={<PowerBIPage />} />  
              <Route path="/upload" element={<UploadFile />} /> 
              <Route path="/chat" element={<Chat/>} /> 
              <Route path="/settings" element={<Settings />} />
            {/* Define other routes here */}
          </Routes>
        </RootLayout>
        </LayoutWrapper>
       
      </Router>
     
  );
};

export default App;