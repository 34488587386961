// src/components/Sidebar.tsx
import React, { useState } from 'react';
import {
  Box, Select, Input, Text, Checkbox, Button, Stack,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';  // Import DatePicker
import 'react-datepicker/dist/react-datepicker.css'; 
import styles from '../styles/FlightsComponent.module.css';  // Import the shared CSS

const Sidebar: React.FC = () => {
  const [loadOption, setLoadOption] = useState<string>("");  // State for load dropdown
  const [viewOption, setViewOption] = useState<string>("");  // State for views dropdown
  const [aircraft, setAircraft] = useState<string>("");      // State for aircraft input
  const [airports, setAirports] = useState<string>("");      // State for airports input
  const [flightType, setFlightType] = useState<string>("");  // State for flight type dropdown
  const [foqaStatus, setFoqaStatus] = useState<string>("");  // State for FOQA status dropdown
  const [startDate, setStartDate] = useState<Date | null>(null);  // Start date
  const [endDate, setEndDate] = useState<Date | null>(null);      // End date

  return (
    <Box width="100%" p={4} height="100vh">  {/* Ensure Sidebar takes full width */}
      <Stack spacing={4}>
        
        {/* Load Flights Dropdown */}
        <Box>
          <Text className={styles['sidebar-header']}>Load</Text>
          <Select 
            placeholder="Select time range" 
            value={loadOption} 
            onChange={(e) => setLoadOption(e.target.value)} 
            className={styles['sidebar-select']}
          >
            <option value="3months">Flights within last 3 months</option>
            <option value="1year">Flights within last year</option>
          </Select>
        </Box>

        {/* Total Flights / Ground Count */}
        <Box>
          <Text className={styles['sidebar-header']}>Total</Text>
          <Text>Flights: 150</Text>  {/* Placeholder values, update with actual data */}
          <Text>Ground: 25</Text>
        </Box>

        {/* Views Dropdown */}
        <Box>
          <Text className={styles['sidebar-header']}>Views</Text>
          <Select 
            placeholder="Select view" 
            value={viewOption} 
            onChange={(e) => setViewOption(e.target.value)} 
            className={styles['sidebar-select']}
          >
            <option value="flights">Flights only</option>
            <option value="ground">Ground only</option>
            <option value="all">All Flights</option>
          </Select>
        </Box>

        {/* Aircraft Input */}
        <Box>
          <Text className={styles['sidebar-header']}>Aircraft</Text>
          <Input 
            placeholder="Select Aircraft" 
            value={aircraft} 
            onChange={(e) => setAircraft(e.target.value)} 
            className={styles['sidebar-input']}
          />
        </Box>

        {/* Airports Input */}
        <Box>
          <Text className={styles['sidebar-header']}>Airports</Text>
          <Input 
            placeholder="Select Airports" 
            value={airports} 
            onChange={(e) => setAirports(e.target.value)} 
            className={styles['sidebar-input']}
          />
        </Box>

        {/* Departure Date Range with DatePicker */}
        <Box>
          <Text className={styles['sidebar-header']}>Departure Date Range</Text>
          <Stack direction="row">
            <DatePicker
              selected={startDate || undefined}  // Pass undefined if startDate is null
              onChange={(date: Date | null) => setStartDate(date)}  // Handle Date | null
              selectsStart
              startDate={startDate || undefined}  // Pass undefined if startDate is null
              endDate={endDate || undefined}  // Pass undefined if endDate is null
              placeholderText="Start Date"
              dateFormat="MM/dd/yyyy"
              className={styles['datepicker-input']}  // Apply custom input style
            />
            <DatePicker
              selected={endDate || undefined}  // Pass undefined if endDate is null
              onChange={(date: Date | null) => setEndDate(date)}  // Handle Date | null
              selectsEnd
              startDate={startDate || undefined}  // Pass undefined if startDate is null
              endDate={endDate || undefined}  // Pass undefined if endDate is null
              placeholderText="End Date"
              dateFormat="MM/dd/yyyy"
              className={styles['datepicker-input']}  // Apply custom input style
            />
          </Stack>
        </Box>

        {/* Flight Type Dropdown */}
        <Box>
          <Text className={styles['sidebar-header']}>Flight Type</Text>
          <Select 
            placeholder="Select Flight Type" 
            value={flightType} 
            onChange={(e) => setFlightType(e.target.value)} 
            className={styles['sidebar-select']}
          >
            <option value="all">All Flights</option>
            <option value="ground">Ground</option>
          </Select>
        </Box>

        {/* FOQA Status Dropdown */}
        <Box>
          <Text className={styles['sidebar-header']}>FOQA Status</Text>
          <Input 
            placeholder="Select FOQA Status" 
            value={foqaStatus} 
            onChange={(e) => setFoqaStatus(e.target.value)} 
            className={styles['sidebar-input']}
          />
        </Box>

        {/* FOQA Comment Checkbox */}
        <Box>
          <Checkbox className={styles['sidebar-input']}>FOQA Comment</Checkbox>
        </Box>

        {/* Significant Flight Checkbox */}
        <Box>
          <Checkbox className={styles['sidebar-input']}>Significant Flight</Checkbox>
        </Box>

        {/* Events Selection */}
        <Box>
          <Text className={styles['sidebar-header']}>Events</Text>
          <Input placeholder="Select Events" className={styles['sidebar-input']} />
          <Button mt={2}>Select Events</Button>
        </Box>

        {/* Search by Flight ID */}
        <Box>
          <Text className={styles['sidebar-header']}>Search Flight Id</Text>
          <Input placeholder="Search Flight Id" className={styles['sidebar-input']} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Sidebar;
