// Refactored from https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/1-Authentication/1-sign-in/SPA/src/authConfig.js

import { IPublicClientApplication } from "@azure/msal-browser";



interface AuthSetup {
    // Set to true if login elements should be shown in the UI
    useLogin: boolean;
    // Set to true if access control is enforced by the application
    requireAccessControl: boolean;
    // Set to true if the application allows unauthenticated access (only applies for documents without access control)
    enableUnauthenticatedAccess: boolean;
    /**
     * Configuration object to be passed to MSAL instance on creation.
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */
    msalConfig: {
        auth: {
            clientId: string; // Client app id used for login
            authority: string; // Directory to use for login https://learn.microsoft.com/entra/identity-platform/msal-client-application-configuration#authority
            redirectUri: string; // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: string; // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: boolean; // If "true", will navigate back to the original request location before processing the auth code response.
        };
        cache: {
            cacheLocation: string; // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: boolean; // Set this to "true" if you are having issues on IE11 or Edge
        };
    };
    loginRequest: {
        /**
         * Scopes you add here will be prompted for user consent during sign-in.
         * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
         * For more information about OIDC scopes, visit:
         * https://learn.microsoft.com/entra/identity-platform/permissions-consent-overview#openid-connect-scopes
         */
        scopes: Array<string>;
    };
    tokenRequest: {
        scopes: Array<string>;
    };
}

// Fetch the auth setup JSON data from the API if not already cached
async function fetchAuthSetup(): Promise<AuthSetup> {
    const response = await fetch("/auth_setup");
    if (!response.ok) {
        throw new Error(`auth setup response was not ok: ${response.status}`);
    }
    return await response.json();
}

const authSetup = await fetchAuthSetup();

//export const useLogin = authSetup.useLogin;

export const useLogin = true;  // Force useLogin to be true for debugging

// Rest of your config remains unchanged
export const requireAccessControl = authSetup.requireAccessControl;
export const enableUnauthenticatedAccess = authSetup.enableUnauthenticatedAccess;
export const requireLogin = requireAccessControl && !enableUnauthenticatedAccess;
export const loginRequest = authSetup.loginRequest;



/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = authSetup.msalConfig;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://learn.microsoft.com/entra/identity-platform/permissions-consent-overview#openid-connect-scopes
 */


const tokenRequest = authSetup.tokenRequest;

// Build an absolute redirect URI using the current window's location and the relative redirect URI from auth setup
export const getRedirectUri = () => {
    console.log("Current window location:", window.location.origin);  // Add this for debugging
    console.log("Redirect URI being used:", window.location.origin + authSetup.msalConfig.auth.redirectUri);
    return window.location.origin + authSetup.msalConfig.auth.redirectUri;
};

/**
 * Determines if the user is logged in via MSAL or Container Apps.
 * @param {IPublicClientApplication | undefined} client - The MSAL public client application instance.
 * @returns {Promise<boolean>} A promise that resolves to true if the user is logged in, false otherwise.
 */
export const checkLoggedIn = async (client: IPublicClientApplication | undefined): Promise<boolean> => {
    const activeAccount = client?.getActiveAccount();
    return !!activeAccount;
};


/**
 * Get an access token for use with the API server.
 * Uses MSAL's acquireTokenSilent to get a token.
 * @param {IPublicClientApplication} client - The MSAL public client application instance.
 * @returns {Promise<string | undefined>} A promise that resolves to an access token, or undefined if the token acquisition fails.
 */
export const getToken = async (client: IPublicClientApplication): Promise<string | undefined> => {
    const activeAccount = client.getActiveAccount();
    if (!activeAccount) {
        console.error("No active account found. Ensure the user is logged in.");
        return undefined;
    }
    try {
        const response = await client.acquireTokenSilent({
            ...tokenRequest,
            redirectUri: getRedirectUri(),
        });
        return response.accessToken;
    } catch (error) {
        console.error(error);
        return undefined;
    }
};

/**
 * Retrieves the username of the active MSAL or built-in auth account.
 * @param {IPublicClientApplication} client - The MSAL public client application instance.
 * @returns {Promise<string | null>} The username of the active account, or null if no account is found.
 */
export const getUsername = async (client: IPublicClientApplication): Promise<string | null> => {
    const activeAccount = client?.getActiveAccount();
    return activeAccount ? activeAccount.username : null;
};

/**
 * Retrieves the token claims of the active account.
 * If no active account is found, attempts to retrieve the token claims from the app services login token if available.
 * @param {IPublicClientApplication} client - The MSAL public client application instance.
 * @returns {Promise<Record<string, unknown> | undefined>} A promise that resolves to the token claims of the active account, the user claims from the app services login token, or undefined if no claims are found.
 */
export const getTokenClaims = async (client: IPublicClientApplication): Promise<Record<string, unknown> | undefined> => {
    const activeAccount = client.getActiveAccount();
    return activeAccount ? activeAccount.idTokenClaims : undefined;
};

/**
 * Retrieves the full name (first and last name) of the active MSAL account.
 * @param {IPublicClientApplication} client - The MSAL public client application instance.
 * @returns {Promise<string | null>} The full name (first and last name) of the active account, or null if no account is found.
 */
export const getFullName = async (client: IPublicClientApplication): Promise<string | null> => {
    const activeAccount = client?.getActiveAccount();
    if (activeAccount && activeAccount.idTokenClaims) {
        const { given_name, family_name } = activeAccount.idTokenClaims as { given_name?: string, family_name?: string };
        if (given_name && family_name) {
            return `${given_name} ${family_name}`;
        }
    }
    return null;
};


