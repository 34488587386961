import { Button } from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import { useState,useEffect,useContext } from "react";
import { LoginContext } from "../contexts/loginContext";
import { getRedirectUri, loginRequest, checkLoggedIn,getUsername } from "../authConfig";
import styles from "../styles/LoginButton.module.css";

const LoginButton = () => {
    const { instance } = useMsal();
    const { loggedIn, setLoggedIn, displayName, setDisplayName } = useContext(LoginContext);
    const activeAccount = instance.getActiveAccount();
    const [username, setUsername] = useState("");
    useEffect(() => {
        const fetchUsername = async () => {
            setUsername((await getUsername(instance)) ?? "");
        };

        fetchUsername();
    }, []);

    const handleLoginPopup = () => {
        console.log("Login button clicked, redirecting to:", getRedirectUri());
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri()
            }).catch(error => {
                console.error("Login Popup Error:", error);
            
            }).then(async () => {
                setLoggedIn(await checkLoggedIn(instance));
                setUsername((await getUsername(instance)) ?? "");
            });
    };

    const handleLogoutPopup = () => {
        if (activeAccount) {
            instance
                .logoutPopup({
                    mainWindowRedirectUri: "/", // redirects the top level app after logout
                    account: activeAccount
                })
                .catch(error => console.log(error))
                .then(async () => {
                    setLoggedIn(false);
                    setUsername((await getUsername(instance)) ?? "");
                    setDisplayName(""); // Clear the display name after logout
                });
        }
    };

    return (
        <Button
            className={styles.loginButton}
            colorScheme={loggedIn ? "red" : "blue"}
            onClick={loggedIn ? handleLogoutPopup : handleLoginPopup}
        >
            {loggedIn ? `Logout (${displayName || username})` : "Login"}
        </Button>
    );
};

export default LoginButton;




   

