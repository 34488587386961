// theme.js (or theme.ts for TypeScript)
import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

// 1. Define color mode configuration
const config: ThemeConfig = {
  initialColorMode: 'dark', // Default mode can be 'light', 'dark', or 'system'
  useSystemColorMode: false, // Set to true if you want to follow the system color mode
};

// 2. Extend the theme with your configuration
const theme = extendTheme({ config });

export default theme;
