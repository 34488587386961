// src/pages/Settings.tsx
import React from 'react';
import { Box, Button, useColorMode, useColorModeValue, Heading } from '@chakra-ui/react';

const Settings: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode(); // Hook to get and toggle color mode

  // Dynamically set background and text colors based on the current mode
  const bg = useColorModeValue('gray.100', 'gray.900');  // Light mode: gray.100, Dark mode: gray.900
  const color = useColorModeValue('black', 'white');     // Light mode: black, Dark mode: white

  return (
    <Box p={6} bg={bg} color={color} minHeight="100vh">
      <Heading mb={4}>Settings</Heading>
      
      <Button onClick={toggleColorMode}>
        {colorMode === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
      </Button>
    </Box>
  );
};

export default Settings;
