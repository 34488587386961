// app/components/PostComponent.tsx
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // Ensure axios is installed

import ConfirmModal from '../components/ConfirmModal';

interface Comment {
  id: number;
  content: string;               
  posted: string;                // Assuming 'posted' holds the datetime or timestamp
  preferred_username?: string;  
  commenter_username?: string;   // Assuming 'commenter_username' holds the username 
  
}

const PostComponent: React.FC = () => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState<string>('');
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  
  
 

  const siteKey = '6LdYa0YpAAAAAGu2HP75Q5iQ-aaLw6JiY7qdbk19';  // This should also be kept in environment variables if it's sensitive

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState<number | null>(null);
  
  const openDeleteModal = (commentId: number) => {
    setShowDeleteModal(true);
    setCommentToDelete(commentId);
  };
  
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    const accessToken = localStorage.getItem('access-token');
    try {
      const response = await axios.delete(`${import.meta.env.VITE_API_URL}/api/comments/${commentToDelete}`, {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
      // Assuming successful deletion returns the expected structure
      if (response.data.data && response.data.message) {
        console.log(response.data.message); // "Comment deleted successfully"
        setComments(comments.filter(comment => comment.id !== commentToDelete)); // Remove deleted post from state
        setShowDeleteModal(false); // Close modal on success
      } 
    } catch (error: any) { // Explicitly type 'error' as 'any'
      console.error("Error deleting comment:", error.response?.data);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);  // Dependency array left empty to run only once after the component mounts

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/comments`);
      console.log('API Response:', response);
      if (response.data && response.data.data) {
        setComments(response.data.data); // Assuming 'data' field contains the comments
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

// Function to post a new comment
const postComment = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  console.log('Attempting to post a new comment:', newComment);

  // Execute reCAPTCHA and get response

  try {
    const captchaResponse = await window.grecaptcha.execute(siteKey, { action: 'submit' });
    console.log("Captcha Response:", captchaResponse);

    const verifyResponse = await axios.post(
      `${import.meta.env.VITE_API_URL}/api/verify_recaptcha`, 
      { captchaResponse, action: 'submit' }
    );
    console.log("Verify Response:", verifyResponse.data);

    // Check reCAPTCHA result
    if (!verifyResponse.data.success || verifyResponse.data.score < 0.5) {
      setRecaptchaError('Suspicious activity detected or reCAPTCHA failed. Try again later.');
      return;
    }
  } catch (error) {
    console.error("Error during reCAPTCHA verification:", error);
    setRecaptchaError('An error occurred during reCAPTCHA verification.');
    return;
  }

  // Post the comment if reCAPTCHA is successful
  try {
    const accessToken = localStorage.getItem('access-token');
    console.log('Access token:', accessToken); 
   const userInfoResponse = await axios.get(`${import.meta.env.VITE_API_URL}/api/user_info`, {
   headers: { 'Authorization': `Bearer ${accessToken}` }
    });

    const preferredUsername = userInfoResponse.data.preferred_username || 'anonymous';

    // Log the actual comment content being sent
    console.log('Sending comment content:', newComment);

    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/api/comments`,
      { content: newComment, preferred_username: preferredUsername },
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    );

    console.log("Response from posting comment:", response);

    // Update comment list and clear input field
    if (response.data &&  response.data.data) {
      setComments([...comments, response.data.data]);
      setNewComment('');
    } else {
      console.error('Unexpected response format:', response.data);
    }
  } catch (error) {
    console.error("Error posting comment:", error);
  }
};

  

  


  return (
    //<MainLayout>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {recaptchaError && <div className="bg-red-500 text-white p-3 rounded-lg">{recaptchaError}</div>}

  
        {/* Bedingt Posts und Kommentare basierend auf Authentifizierung rendern */}
        
        {/* Posts und Comments */}
        {comments.map(comment => (
          <div key={comment.id} className="flex flex-col mb-4 p-4 bg-white shadow-md rounded-lg">
            <div className="text-gray-700">{comment.content}</div>
            <div className="text-sm text-gray-600 mt-2">
                Posted
                <span className="mx-1">
                {comment.posted ? new Date(comment.posted).toLocaleString() : 'at an unknown time'}
              </span>
              by
              <span className="mx-1 font-semibold">
                {comment.preferred_username ? `${comment.preferred_username}` : 'anonymous'}
              </span>
              <button 
                className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-lg ml-auto"
                onClick={() => openDeleteModal(comment.id)}>
                Delete
              </button>
            </div>
          </div>
        ))}
  
        {/* Kommentarformular */}
        {(
           <div className="mt-6">
           <form onSubmit={postComment} className="flex flex-col space-y-4">
             <textarea
               className="form-control p-2 text-gray-700 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
               value={newComment}
               onChange={(e) => setNewComment(e.target.value)}
               placeholder="Enter a comment"
               rows={3}
             ></textarea>
             <input type="submit" className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg cursor-pointer" value="Post comment" />
           </form>
         </div>
       )}
        <ConfirmModal 
        isOpen={showDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this comment?"
      />      
      </div>
    
   // </MainLayout>
  );  
};
export default PostComponent;