// /layouts/MainLayout.tsx
import React from 'react';
import NavBar from '../components/NavBar';
import styles from '../styles/headerFooter.module.css'; 
import { FaPhone, FaEnvelope } from 'react-icons/fa'; // Importing icons
import { FlagIcon } from 'react-flag-kit';


const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
      <>
          <header>
              <div className={styles.logoRow}>
                  {/* Use the path from the public directory */}
                  <img src="/images/Lentinis_Logo_RGB383841.png" alt="Logo" className={styles.logoRowImg} />
              </div>
          </header>
          {/* No need to pass username anymore */}
          <NavBar />
          <main>{children}</main>
          <footer className={styles.appFooter}>
              <div className={styles.footerContent}>
                  LENTINIS | <FlagIcon code="US" size={24} /> USA | <FlagIcon code="CH" size={24} /> SWITZERLAND
                  <FaPhone /> +1 123 456 7890
                  <FaEnvelope /> email@lentinis.ch
                  <p>Disclaimer</p>
              </div>
          </footer>
      </>
  );
};

export default MainLayout;


